import React, { MouseEvent } from "react";
import { AxiosError, AxiosResponse } from "axios";
import LookupService from "../../services/LookupService";
import { LoadPanel, NumberBox } from "devextreme-react";
import PaymentService, { paymentStatus } from "../../services/PaymentService";
import { ActionType } from "../Billing/BillingFormUtils";
import sharedUtils from "../grid/sharedUtilities";
import paymentFormUtils, { PaymentFormData } from "../Payments/PaymentFormUtils";
import UserService, { RoleGroupNames } from "../../services/UserService";
import NewPaymentsGrid from "./NewPaymentsGrid";
import GlobalService from "../../services/GlobalService";
import SimpleShiftsByService from "../SimpleShiftsByService/SimpleShiftsByService";
import FutureNegativeShiftGrid from "../ShiftMatrix/FutureNegativeShiftGrid";

// Props
interface PaymentsAndChecksComponentProps {
    viewDisabled: boolean;
    paymentCycleId: string;
    paymentData: PaymentFormData;
    onClickPaymentsStatus: (clickedOn: string, status: string, statusId: string) => void;
    setCalculationsCallback: (calculationsObject: { subTotal: string; total: string; tax: string }) => void;
    isClickedRestart: boolean;
    statusId: string;
    isPrepareClicked: boolean;
    isSuccessRestart: boolean;
    calculationObject: {
        subTotal: string;
        total: string;
        tax: string;
    };
    refreshPage: boolean;
}

// State
interface PaymentsAndChecksComponentState {
    loadPanelVisible: boolean;
    calculationsObject: {
        subTotal: string;
        total: string;
        tax: string;
    };
    paymentsRefresh: boolean;
    isShowPaymentGrid: boolean;
    paymentId: string;
    isShowBillableItemGrid: boolean;
    refreshBillableItemGrid: boolean;
    isDisableLockPayments: boolean;
    isDisableUpdatePayments: boolean;
    isDisableCheckPayments: boolean;
    simpleShiftGridRefreshSignal: boolean;
}
/**
 * Component - Spline chart to display budget and spend
 */
class PaymentsAndChecksComponent extends React.Component<PaymentsAndChecksComponentProps> {
    userHasAccess: boolean;
    state: PaymentsAndChecksComponentState;
    lookupService: LookupService;
    paymentService: PaymentService;
    utils: paymentFormUtils;
    sharedUtils: sharedUtils;
    constructor(props: PaymentsAndChecksComponentProps) {
        super(props);
        this.lookupService = new LookupService();
        this.paymentService = new PaymentService();
        this.utils = new paymentFormUtils();
        this.sharedUtils = new sharedUtils();
        this.userHasAccess =
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager);
        this.state = {
            loadPanelVisible: false,
            calculationsObject: {
                subTotal: "0.00",
                total: "0.00",
                tax: "0.00",
            },
            paymentsRefresh: false,
            isShowPaymentGrid: false,
            paymentId: "",
            isShowBillableItemGrid: false,
            refreshBillableItemGrid: false,
            isDisableLockPayments: false,
            isDisableUpdatePayments: false,
            isDisableCheckPayments: false,
            simpleShiftGridRefreshSignal: false,
        };
    }

    componentDidUpdate(prevProps: PaymentsAndChecksComponentProps) {
        if (this.props.statusId != prevProps.statusId) {
            if (this.props.statusId != prevProps.statusId && this.props.statusId == paymentStatus.PaymentNetted) {
                this.setState({
                    isDisableCheckPayments: true,
                    isDisableLockPayments: false,
                    isDisableUpdatePayments: false,
                    paymentsRefresh: !this.state.paymentsRefresh,
                    refreshBillableItemGrid: !this.state.refreshBillableItemGrid,
                    isShowPaymentGrid: true,
                    isShowBillableItemGrid: false,
                });
            } else if (this.props.statusId != prevProps.statusId && this.props.statusId == paymentStatus.Pending) {
                this.setState({
                    isDisableLockPayments: true,
                    isDisableUpdatePayments: false,
                    isDisableCheckPayments: true,
                    isShowPaymentGrid: false,
                    isShowBillableItemGrid: false,
                    calculationsObject: {
                        ...this.state.calculationsObject,
                        total: 0.0,
                        subTotal: 0.0,
                        tax: 0.0,
                    },
                });
            } else if (
                (this.props.statusId != prevProps.statusId && this.props.statusId == paymentStatus.PaymentChecked) ||
                this.props.statusId == paymentStatus.PaymentPackRequested ||
                this.props.statusId == paymentStatus.PaymentPackReady ||
                this.props.statusId == paymentStatus.PaymentPackFailed ||
                this.props.statusId == paymentStatus.PaymentPaid ||
                this.props.statusId == paymentStatus.PaymentNotificationRequested || 
                this.props.statusId == paymentStatus.PaymentNotification ||
                this.props.statusId == paymentStatus.PaymentEmailNotificationRequested || 
                this.props.statusId == paymentStatus.PaymentEmailNotification ||
                this.props.statusId == paymentStatus.PaymentAdviseRequested ||
                this.props.statusId == paymentStatus.PaymentAdvised ||
                this.props.statusId == paymentStatus.PaymentSettled ||
                this.props.statusId == paymentStatus.PaymentRemittanceRequested ||
                this.props.statusId == paymentStatus.PaymentRemitted
            ) {
                this.setState({
                    isDisableLockPayments: true,
                    isDisableUpdatePayments: true,
                    isDisableCheckPayments: true,
                    isShowPaymentGrid: true,
                    paymentsRefresh: !this.state.paymentsRefresh,
                    refreshBillableItemGrid: !this.state.refreshBillableItemGrid,
                    isShowBillableItemGrid: false,
                });
            } else if (this.props.isPrepareClicked != prevProps.isPrepareClicked) {
                this.setState((prevState: PaymentsAndChecksComponentState) => ({
                    isDisableUpdatePayments: false,
                }));
            }
        }
        if (
            this.props.calculationObject.subTotal != prevProps.calculationObject.subTotal ||
            this.props.calculationObject.total != prevProps.calculationObject.total ||
            this.props.calculationObject.tax != prevProps.calculationObject.tax
        ) {
            this.setState({
                calculationsObject: this.props.calculationObject,
            });
        }
        if (this.props.refreshPage != prevProps.refreshPage) {
            this.setState({
                paymentsRefresh: !this.state.paymentsRefresh
            });
        }
    }

    componentDidMount() {
        GlobalService.removePaymentCycleIds();
        if (this.props.statusId == paymentStatus.PaymentNetted) {
            this.setState({
                isDisableCheckPayments: true,
                isDisableLockPayments: false,
                isDisableUpdatePayments: false,
                paymentsRefresh: !this.state.paymentsRefresh,
                refreshBillableItemGrid: !this.state.refreshBillableItemGrid,
                isShowPaymentGrid: true,
                isShowBillableItemGrid: false,
            });
        } else if (this.props.statusId == paymentStatus.Pending) {
            this.setState({
                isDisableLockPayments: true,
                isDisableUpdatePayments: false,
                isDisableCheckPayments: true,
                isShowPaymentGrid: false,
                isShowBillableItemGrid: false,
            });
        } else if (
            this.props.statusId == paymentStatus.PaymentChecked ||
            this.props.statusId == paymentStatus.PaymentPackRequested ||
            this.props.statusId == paymentStatus.PaymentPackReady ||
            this.props.statusId == paymentStatus.PaymentPackFailed || 
            this.props.statusId == paymentStatus.PaymentPaid ||
            this.props.statusId == paymentStatus.PaymentNotificationRequested || 
            this.props.statusId == paymentStatus.PaymentNotification ||
            this.props.statusId == paymentStatus.PaymentEmailNotificationRequested || 
            this.props.statusId == paymentStatus.PaymentEmailNotification ||
            this.props.statusId == paymentStatus.PaymentAdviseRequested ||
            this.props.statusId == paymentStatus.PaymentAdvised ||
            this.props.statusId == paymentStatus.PaymentSettled ||
            this.props.statusId == paymentStatus.PaymentRemittanceRequested ||
            this.props.statusId == paymentStatus.PaymentRemitted
        ) {
            this.setState({
                isDisableLockPayments: true,
                isDisableUpdatePayments: true,
                isDisableCheckPayments: true,
                isShowPaymentGrid: true,
                paymentsRefresh: !this.state.paymentsRefresh,
                refreshBillableItemGrid: !this.state.refreshBillableItemGrid,
                isShowBillableItemGrid: false,
            });
        }
    }

    updatePayments = () => {
        if (this.userHasAccess) {
            this.setState({
                loadPanelVisible: true,
            });
            this.paymentService
                .generatePaymentsV2(this.props.paymentCycleId)
                .then(this.handlePostSuccess)
                .catch(this.handlePostFailure);
        }
    };

    handlePostSuccess = (response: AxiosResponse<any>) => {
        this.setState(
            {
                loadPanelVisible: false,
                paymentsRefresh: !this.state.paymentsRefresh,
                isShowPaymentGrid: true,
                isDisableLockPayments: false,
            },
            () => {
                this.props.onClickPaymentsStatus(
                    "Update Payments",
                    paymentStatus.PaymentNetted,
                    paymentStatus.PaymentNetted
                );
            }
        );
    };

    handlePostFailure = (error: AxiosError<any>) => {
        this.setState({
            loadPanelVisible: false,
        });
    };

    setCalculations = (calculations: any) => {
        if (calculations.total || calculations.subTotal || calculations.tax) {
            this.setState({
                calculationsObject: {
                    ...this.state.calculationsObject,
                    total: calculations.total,
                    subTotal: calculations.subTotal,
                    tax: calculations.tax,
                },
            },
                () => this.props.setCalculationsCallback(this.state.calculationsObject)
            );
        }
        this.setState({
            Load: true,
        });
    };

    setPaymentCycleIdOnParent = (paymentId: string) => {
        this.setState({
            paymentId: paymentId,
            isShowBillableItemGrid: true,
            refreshBillableItemGrid: !this.state.refreshBillableItemGrid,
        });
    };

    onLockShift = () => {
        if (this.userHasAccess) {
            this.setState(
                {
                    loadPanelVisible: true,
                },
                () => {
                    this.lockPaymentFormData(this.handleLockPaymentSuccess, "");
                }
            );
        }
    };

    lockPaymentFormData(successHandler: (response: AxiosResponse<any>) => void, statusIdForNextStep: string): void {
        var lockPaymentSubmitRequest = this.utils.convertFormDataToPaymentCycleRequest(
            this.props.paymentData,
            "Lock",
            statusIdForNextStep,
            this.props.paymentData.statusId
        );
        var excludedPaymentIds = GlobalService.getPaymentCycleIds();
        lockPaymentSubmitRequest.excludedPaymentIds = excludedPaymentIds;
        lockPaymentSubmitRequest.status = "Payment Netted";
        lockPaymentSubmitRequest.statusId = "20";
        lockPaymentSubmitRequest.subTotal = this.state.calculationsObject.subTotal;
        lockPaymentSubmitRequest.total = this.state.calculationsObject.total;
        lockPaymentSubmitRequest.tax = this.state.calculationsObject.tax;
        this.paymentService
            .postPaymentCycleDataV2(lockPaymentSubmitRequest)
            .then(successHandler)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null,
                    IsShowSuccess: false,
                });
                this.hideLoadpanel();
            })
            .finally(() => {
                this.hideLoadpanel();
            });
    }

    hideLoadpanel = () => {
        this.setState({ loadPanelVisible: false });
    };

    handleLockPaymentSuccess = (response: AxiosResponse<any>) => {
        var paymentCycleId = response.data.toString();
        sessionStorage.setItem("paymentCycleId", paymentCycleId);
        this.setState(
            {
                paymentsRefresh: !this.state.paymentsRefresh,
                isDisableLockPayments: true,
                isDisableUpdatePayments: true,
                isDisableCheckPayments: false,
                simpleShiftGridRefreshSignal: !this.state.simpleShiftGridRefreshSignal,
            },
            () => {
                this.props.onClickPaymentsStatus(
                    "LockPayments",
                    paymentStatus.PaymentNetted,
                    paymentStatus.PaymentNetted
                );
            }
        );
        GlobalService.removePaymentCycleIds();
    };

    onClickCheck = () => {
        if (this.userHasAccess) {
            this.setState(
                {
                    loadPanelVisible: true,
                },
                () => {
                    this.checkPaymentFormData(this.handlecheckPaymentSuccess, "");
                }
            );
        }
    };

    checkPaymentFormData(successHandler: (response: AxiosResponse<any>) => void, statusIdForNextStep: string): void {
        var checkPaymentSubmitRequest = this.utils.convertFormDataToPaymentCycleRequest(
            this.props.paymentData,
            ActionType.Save,
            statusIdForNextStep,
            this.props.paymentData.statusId
        );
        checkPaymentSubmitRequest.status = "Payment Checked";
        checkPaymentSubmitRequest.statusId = "30";
        checkPaymentSubmitRequest.subTotal = this.state.calculationsObject.subTotal;
        checkPaymentSubmitRequest.total = this.state.calculationsObject.total;
        checkPaymentSubmitRequest.tax = this.state.calculationsObject.tax;
        this.paymentService
            .postPaymentCycleDataV2(checkPaymentSubmitRequest)
            .then(successHandler)
            .catch((err) => {
                this.setState({
                    errorMessage: err.response !== null ? JSON.parse(JSON.stringify(err.response.data.error)) : null,
                    IsShowSuccess: false,
                });
                this.hideLoadpanel();
            })
            .finally(() => {
                this.hideLoadpanel();
            });
    }

    handlecheckPaymentSuccess = (response: AxiosResponse<any>) => {
        var paymentCycleId = response.data.toString();
        sessionStorage.setItem("paymentCycleId", paymentCycleId);
        this.setState(
            {
                isDisableLockPayments: true,
                isDisableUpdatePayments: true,
                isDisableCheckPayments: true,
            },
            () => {
                this.props.onClickPaymentsStatus(
                    "CheckPayments",
                    paymentStatus.PaymentChecked,
                    paymentStatus.PaymentChecked
                );
            }
        );
    };

    updateClicked = (flag: boolean) => { 
        if(flag == true){
            this.setState(
                {
                    loadPanelVisible: false,
                    paymentsRefresh: !this.state.paymentsRefresh,
                    isShowPaymentGrid: true,
                    isDisableLockPayments: false,
                },
                () => {
                    this.props.onClickPaymentsStatus(
                        "Update Payments",
                        paymentStatus.PaymentNetted,
                        paymentStatus.PaymentNetted
                    );
                }
            );
        }
    };

    setCalculatedValue = () => { };

    render() {
        var updatePaymentClass: string = "";
        var lockPaymentClass: string = "";
        var checkPaymentClass: string = "";

        if (this.state.isDisableUpdatePayments || this.props.viewDisabled) {
            updatePaymentClass = "btn disabledCycleButtonColor btn--large";
        } else {
            updatePaymentClass = "btn saveCycleButtonColor btn--large";
        }
        if (this.state.isDisableLockPayments || this.props.viewDisabled) {
            lockPaymentClass = "btn disabledCycleButtonColor btn--large";
        } else {
            lockPaymentClass = "btn saveCycleButtonColor btn--large";
        }
        if (this.state.isDisableCheckPayments || this.props.viewDisabled) {
            checkPaymentClass = "btn disabledCycleButtonColor btn--large";
        } else {
            checkPaymentClass = "btn saveCycleButtonColor btn--large";
        }
        return (
            <div className="card mt-3">
                <div className="card-body">
                    <div className="col-12">
                        <>
                            <h5>Payments & Checks</h5>
                            <LoadPanel shadingColor="rgba(0,0,0,0.4)" visible={this.state.loadPanelVisible} />
                            <div className="row">
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Provider Net</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        disabled={true}
                                        value={
                                            this.state.calculationsObject
                                                ? this.state.calculationsObject.subTotal
                                                    ? parseFloat(this.state.calculationsObject.subTotal)
                                                    : 0.0
                                                : undefined
                                        }
                                    />
                                </div>
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">VAT</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        disabled={true}
                                        value={
                                            this.state.calculationsObject
                                                ? this.state.calculationsObject.tax
                                                    ? parseFloat(this.state.calculationsObject.tax)
                                                    : 0.0
                                                : undefined
                                        }
                                    />
                                </div>
                                <div className="mt-4 col-2 col-lg-1 font-weight-bold font_size">Provider Gross</div>
                                <div className="mt-3 col-10 col-lg-3">
                                    <NumberBox
                                        format={{
                                            type: "currency",
                                            precision: 2,
                                            currency: "GBP",
                                        }}
                                        disabled={true}
                                        value={
                                            this.state.calculationsObject
                                                ? this.state.calculationsObject.tax
                                                    ? parseFloat(this.state.calculationsObject.total)
                                                    : 0.0
                                                : undefined
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12 col-lg-3 mb-3 mr-auto">
                                    <button
                                        className={updatePaymentClass}
                                        type="button"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.updatePayments();
                                        }}
                                        disabled={this.props.viewDisabled ? true : this.state.isDisableUpdatePayments}
                                    >
                                        <span className="btn__icon">
                                            {/* <FontAwesomeIcon icon={faQuestionCircle} /> */}
                                        </span>
                                        UPDATE PAYMENTS
                                    </button>
                                </div>
                            </div>
                        </>
                    </div>
                    {this.state.isShowPaymentGrid ? (
                        <div className="col-12 mt-3">
                            <>
                                <h5>Generated Payments</h5>
                                <NewPaymentsGrid
                                    id={this.props.paymentCycleId}
                                    setCalculations={this.setCalculations}
                                    setPaymentCycleIdOnParent={this.setPaymentCycleIdOnParent}
                                    paymentsRefresh={this.state.paymentsRefresh}
                                    pontOfNavigation="paymentsandchecks"
                                ></NewPaymentsGrid>
                            </>
                        </div>
                    ) : null}
                    {this.state.isShowBillableItemGrid ? (
                        <div className="col-12 mt-3">
                            <>
                                <h5>Included Shifts within the Payment Selected</h5>
                                <SimpleShiftsByService
                                    clientId={""}
                                    venueId={""}
                                    serviceTypeId={""}
                                    startDate={""}
                                    endDate={""}
                                    setCalculations={this.setCalculatedValue}
                                    paymentId={this.state.paymentId}
                                    burgerMenuName={""}
                                    refreshSignal={this.state.simpleShiftGridRefreshSignal}
                                    getBuild={() => { return null }}
                                />
                            </>
                        </div>
                    ) : null}
                    {this.state.isShowPaymentGrid ? (
                        <div className="col-12 mt-3">
                            <>
                                <h5>Future Negative Shifts</h5>
                                {/* <EventUkUserGrid
                                    setParentButtonStatesSignal={this.setParentButtonStatesSignal}
                                    setBillableItemObj={()=>{}}
                                    setBillableItemList={this.setBillableItemList}
                                    setSummary={this.setSummary}
                                    setIndicatorStates={this.setIndicatorStates}
                                    setCalculations={this.setCalculations}
                                    refreshSignal={this.state.simpleShiftGridRefreshSignal}
                                    gridType="FutureNegetiveShift" //The GridType can take in three of the possible values, "EventUk", "Billing", or "Payments"
                                    id={parseInt(this.props.paymentCycleId)} // To fetch in the List of Billable items for a particular Invoice ID.,
                                    filterText=""
                                    billableItemId=""
                                    clientId=""
                                    venueId=""
                                    shiftStart=""
                                    shiftEnd=""
                                    isSchedulePage={false}
                                    signalForArrowClick=""
                                    serviceTypeValue=""
                                    dateFrom=""
                                    dateTo=""
                                    setMatchButtonState={()=>{}}
                                    setRemoveButtonState={()=>{}}
                                    setBillableItemCount={()=>{return null}}
                                /> */}
                                <FutureNegativeShiftGrid id={this.props.paymentCycleId} payDate={this.props.paymentData.payDate} updateClicked={this.updateClicked}/>
                            </>
                        </div>
                    ) : null}
                    {this.state.isShowPaymentGrid ? (
                        <div className="col-12">
                            <div className="row mt-3">
                                <div className="col-12 col-lg-2 mb-3">
                                    <button
                                        className={lockPaymentClass}
                                        type="button"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onLockShift();
                                        }}
                                        disabled={this.props.viewDisabled ? true : this.state.isDisableLockPayments}
                                    >
                                        <span className="btn__icon">
                                            {/* <FontAwesomeIcon icon={faQuestionCircle} /> */}
                                        </span>
                                        LOCK PAYMENTS
                                    </button>
                                </div>
                                <div className="col-12 col-lg-2 mb-3">
                                    <button
                                        className={checkPaymentClass}
                                        type="button"
                                        onClick={(e: MouseEvent) => {
                                            e.preventDefault();
                                            this.onClickCheck();
                                        }}
                                        disabled={this.props.viewDisabled ? true : this.state.isDisableCheckPayments}
                                    >
                                        <span className="btn__icon">
                                            {/* <FontAwesomeIcon icon={faQuestionCircle} /> */}
                                        </span>
                                        CHECK
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default PaymentsAndChecksComponent;
