import { DataGridOnSelectionChanged } from "../../types/DevExtremeTypes";
import { months } from "./sharedUtilities";
import BillableItemHeaderService, {
    BillableItemHeaderServiceRowItem,
    BillableItemHeaderStatuses,
    ShiftImportChildGridRowItem,
    UploadFileListGridSections,
} from "../../services/BillableItemHeaderService";
import { TransactionGridRowItem, WeekOnWeekGridRowItem } from "../../services/ReconciliationGridService";
import { eventUkUserGridRowItem,ShiftMatrixRemoteGridRowItem } from "../../services/SimpleGridService";
import UserService, { RoleGroupNames } from "../../services/UserService";
import moment from "moment";

/**
 * Language support is not yet fully implemented in the database.
 * This object will be used until the database can tell the UI which currency to use.
 * This may not be a perfect location for this object, but it is a good place to start.
 */
export const currencyTypes = {
    GBP: "GBP",
    EUR: "EUR",
    USD: "USD",
};

interface CurrencyDictionary {
    [key: string]: string;
}

export const currencyTypeToSymbolMatrix: CurrencyDictionary = {
    [currencyTypes.GBP]: "£",
    [currencyTypes.EUR]: "€",
    [currencyTypes.USD]: "$",
};

export interface IdListObject {
    approvableBillableIdList: number[];
    notaApprovableBillableIdList: number[];
}
export interface DeleteIdListObject {
    deletableIdList: number[];
    notdeletableIdList: number[];
}
export const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export default class GridUtils {
    billableItemHeaderService: BillableItemHeaderService;
    constructor() {
        this.convertUTCStringToDate = this.convertUTCStringToDate.bind(this);
        this.convertddmmyyyyStringToDate = this.convertddmmyyyyStringToDate.bind(this);
        this.convertDecimalAsStringToFloat = this.convertDecimalAsStringToFloat.bind(this);
        this.convertCurrencyAsStringToFloat = this.convertCurrencyAsStringToFloat.bind(this);
        this.convertDecimalAsStringToCurrency = this.convertDecimalAsStringToCurrency.bind(this);
        this.convertNumberAsStringToInteger = this.convertNumberAsStringToInteger.bind(this);
        this.billableItemHeaderService = new BillableItemHeaderService();
    }

    // To enable proper sorting and take local time into account, the UTC string from the server must be converted into a Date
    // This string is formatted using proper UTC string value vs "dd/mm/yyyy"
    convertUTCStringToDate(rowValue: string): Date | undefined {
        return rowValue && rowValue.length > 0 ? new Date(rowValue) : undefined;
    }
    // convert id column ( string to Int)
    convertStringToInt = (data: string) => {
        if (data) {
            return parseInt(data);
        }
    }

    // Convert a string formated "dd/mm/yyyy" into a Date.
    convertddmmyyyyStringToDate(rowValue: string): Date | undefined {
        var convertedDate = undefined;
        if (rowValue && rowValue.length > 0) {
            var dateParts = rowValue.split("/");
            var day = parseInt(dateParts[0]);
            var month = parseInt(dateParts[1]);
            var year = parseInt(dateParts[2]);
            convertedDate = new Date(year, month - 1, day, 0, 0, 0, 0);
        }
        return convertedDate;
    }

    getDaysFromDate(date:any){
    let dayName ='';
    if(date){
    let dayIndex = date.getDay();
     dayName = days[dayIndex];
    }
     return dayName;
    }

    //Convert a string such as "01-sep-20" to the proper dd/mm/yyyy format.
    convertPredefinedStringToddmmyyyyFormat = (rowValue: string): Date | undefined => {
        if (rowValue && rowValue.length > 0) {
            var segregatedDates = rowValue.split("-");
            var day = segregatedDates[0];
            var month = months.findIndex((item) => {
                return item.trim().localeCompare(segregatedDates[1]) == 0;
            });
            var year = `${"20"}${segregatedDates[2]}`;
            var dateFormat = this.convertddmmyyyyStringToDate(`${day}/${(month + 1).toString()}/${year}`);
        }
        return dateFormat;
    };

    // Convert a string formated "HH:mm" into a Time.
    converthhmmStringToTime(rowValue: string): Date | undefined {
        var convertedTime = undefined;

        if (rowValue && rowValue.length > 0) {
            var timeParts = rowValue.split(":");
            var hours = parseInt(timeParts[0]);
            var mins = parseInt(timeParts[1]);

            convertedTime = new Date(0, 0, 0, hours, mins, 0, 0);
        }

        return convertedTime;
    }
    // Convert the string value (which does not sort correctly) into a proper float, which will sort as expected.
    // Format of string does not inclue a currency symbol ("nn.nn") as opposed to "$nn.nn".
    convertDecimalAsStringToFloat(rowValue: string): number {
        return rowValue && rowValue.length > 0 ? parseFloat(rowValue) : 0;
    }
    convertNumberAsStringToInteger(rowValue: string): number {
        return rowValue && rowValue.length > 0 ? parseInt(rowValue) : 0;
    }
    // add two decimal in number
    convertStringToFloatWithDecimal(rowValue: string): any {
        return rowValue && rowValue.length > 0 ? parseFloat(rowValue).toFixed(2) : 0.00;
    }

    convertStringToFloat(value: string) {
        return value ? parseFloat(value) : 0.0;
    }

    /**
     * Convert the decimal value into a currency value that has the format: {currencySymbol}nnnn.nn
     *
     * @param rowValue {string} - the decimal value to convert
     * @param currencySymbol {string} - the currency symbol to prepend
     */
    convertDecimalAsStringToCurrency(rowValue: string, currencySymbol: string): string {
        var amountAsFloat = this.convertStringToFloatWithDecimal(rowValue);
        return currencySymbol
            ? `${currencySymbol}${amountAsFloat.toLocaleString()}`
            : `${currencyTypeToSymbolMatrix[currencyTypes.GBP]}${amountAsFloat.toLocaleString()}`;
    }

    convertDecimalToCurrency(rowValue: number, currencySymbol: string): string {
        var amountAsFloat = rowValue > 0 ? rowValue.toFixed(2) : 0.0;
        return `${currencySymbol}${amountAsFloat}`;
    }

    // Convert the string value (which does not sort correctly) into a proper float, which will sort as expected.
    // Format of string includes a currency symbol "$nn.nn"
    convertCurrencyAsStringToFloat(rowValue: string): number {
        // Some data only has the currency symbol ("$"), skip these cases.
        if (rowValue && rowValue.length > 1) {
            return this.convertDecimalAsStringToFloat(rowValue.substr(1));
        } else {
            return 0;
        }
    }

    determineListOfRequestedCards(name: string): string[] {
        let requestedCards = [];
        if (name && name.length > 0) {
            requestedCards.push(name);
        }
        return requestedCards;
    }

    determineBilliableItemList = (event: DataGridOnSelectionChanged): string => {
        var billableItemIdList: string = "";
        if (event.selectedRowsData) {
            event.selectedRowsData.forEach((element) => {
                // billableItemIdList.push(parseInt(element.billableItemId))
                billableItemIdList += element.billableItemId + ",";
            });
        }
        return billableItemIdList;
    };

    //Separate the string into an array of 2 elements based on the "-" or "or" symbol.
    convertStringToStringArray(rowValue: string): string[] {
        const checkinOutConstant: string = "No";
        var values: string[] = [];
        if (rowValue && rowValue.length > 0) {
            if (rowValue.includes("-")) {
                var parts = rowValue.split("-");
                parts.forEach((element) => {
                    values.push(element.trim());
                });
            } else {
                parts = rowValue.split("or");
                parts[1] = checkinOutConstant + parts[1];
                parts.forEach((element) => {
                    values.push(element.trim());
                });
            }
        }
        return values;
    }

    //Separate the string into an array of 2 elements based on the "-" or "or" symbol.
    convertServiceType = (rowValue: string): string[] => {
        var values: string[] = [];
        if (rowValue && rowValue.length > 0) {
            if (rowValue.includes("-")) {
                var parts = rowValue.split("-");
                parts.forEach((element) => {
                    values.push(element.trim());
                });
            } else {
                values.push(rowValue);
                values.push("");
            }
        }
        return values;
    };
    //Purpose: To trim off the extra underscore character from the file name.
    trimFilename = (rowValue: string): string => {
        var modifiedFileName: string = "";
        if (rowValue && rowValue.length > 0) {
            var index: number = rowValue.indexOf("_");
            modifiedFileName = rowValue.substring(index + 1, rowValue.length);
        }
        return modifiedFileName;
    };

    initializeUploadFileListGridSections = (): UploadFileListGridSections => {
        return {
            all: [],
            newUploaded: [],
        };
    };

    //A helper function that will flter the response based on the "Submitted" and "Pending" files.
    filterShiftImportGrid = (
        shiftImportDataSource: BillableItemHeaderServiceRowItem[],
        billableItemHeaderId: string
    ) => {
        var gridSections: UploadFileListGridSections = this.initializeUploadFileListGridSections();
        shiftImportDataSource.forEach((shiftImportDataItem: BillableItemHeaderServiceRowItem) => {
            if (billableItemHeaderId && billableItemHeaderId == shiftImportDataItem.id) {
                gridSections.newUploaded.push(shiftImportDataItem);
            } else {
                gridSections.all.push(shiftImportDataItem);
            }
        });
        return gridSections;
    };
    removeRate = (
        childGridDataSource: ShiftImportChildGridRowItem[]
    ): ShiftImportChildGridRowItem[] => {
        var removeRateDataSource: ShiftImportChildGridRowItem[] = [];
        childGridDataSource.forEach((shiftImportChildGridDataItem: ShiftImportChildGridRowItem) => {
            if (shiftImportChildGridDataItem.errorMessage) {
                removeRateDataSource = JSON.parse(shiftImportChildGridDataItem.errorMessage);
                removeRateDataSource = removeRateDataSource.filter((item: any) => {
                    return item.ColumnName !== "Rate";
                });
            }
            shiftImportChildGridDataItem.errorMessage = JSON.stringify(removeRateDataSource);
        });
        childGridDataSource = childGridDataSource.filter((item: any) => {
            return item.errorMessage !== "[]";
        });
        return childGridDataSource;
    };

    /**
     * Determine if the status button should be disabled based on current row status
     *
     * @param status {string} - current row status
     * @returns {boolean} - true if the button should be disabled
     */
    getStatusButtonDisabledState(status: string): boolean {
        return this.billableItemHeaderService.isStatusEqualTo(status, BillableItemHeaderStatuses.Pending) ||
            this.billableItemHeaderService.isStatusEqualTo(status, BillableItemHeaderStatuses.SubmissionInProgress) ||
            this.billableItemHeaderService.isStatusEqualTo(status, BillableItemHeaderStatuses.InProgress) ||
            this.billableItemHeaderService.isStatusEqualTo(status, BillableItemHeaderStatuses.Submitted) ||
            this.billableItemHeaderService.isStatusEqualTo(status, BillableItemHeaderStatuses.NotAccepted) ||
            this.billableItemHeaderService.isStatusEqualTo(status, BillableItemHeaderStatuses.Uploaded) ||
            this.billableItemHeaderService.isStatusEqualTo(status, BillableItemHeaderStatuses.Failed) || 
            this.billableItemHeaderService.isStatusEqualTo(status, BillableItemHeaderStatuses.postRemovalArchived) || 
            this.billableItemHeaderService.isStatusEqualTo(status, BillableItemHeaderStatuses.postRemovalComplete) || 
            this.billableItemHeaderService.isStatusEqualTo(status, BillableItemHeaderStatuses.postRemovalFailed) ||
            this.billableItemHeaderService.isStatusEqualTo(status, BillableItemHeaderStatuses.postRemovalInProgress)
            ? true
            : false;
    }

    //A helper function that will flter the response based on the "All" and "Issues/Warnings" files.
    filterShiftImportChildGrid = (
        childGridDataSource: ShiftImportChildGridRowItem[]
    ): ShiftImportChildGridRowItem[] => {
        var warningDataSource: ShiftImportChildGridRowItem[] = [];
        childGridDataSource.forEach((shiftImportChildGridDataItem: ShiftImportChildGridRowItem) => {
            if (
                shiftImportChildGridDataItem.billableItemUploadStatus.localeCompare("Warning") == 0 ||
                shiftImportChildGridDataItem.billableItemUploadStatus.localeCompare("Error") == 0
            ) {
                warningDataSource.push(shiftImportChildGridDataItem);
            }
        });
        return warningDataSource;
    };

    //Function used in Transaction Grid.
    splitTransactionReportData = (dataSource: TransactionGridRowItem[]): TransactionGridRowItem[] => {
        var reportItems: TransactionGridRowItem[] = [];
        if (dataSource && dataSource.length > 0) {
            for (var item = 0; item < dataSource.length; item++) {
                if (
                    this.convertDecimalAsStringToFloat(dataSource[item].rateDiff) ||
                    this.convertDecimalAsStringToFloat(dataSource[item].feeDiff)
                ) {
                    reportItems.push(dataSource[item]);
                }
            }
        }
        return reportItems;
    };

    //Function used in weekly variance Grid.
    splitVarianceReportData = (dataSource: WeekOnWeekGridRowItem[]): WeekOnWeekGridRowItem[] => {
        var reportItems: WeekOnWeekGridRowItem[] = [];
        if (dataSource && dataSource.length > 0) {
            for (var item = 0; item < dataSource.length; item++) {
                if (this.convertDecimalAsStringToFloat(dataSource[item].varience)) {
                    reportItems.push(dataSource[item]);
                }
            }
        }
        return reportItems;
    };

    //A helper function which would order the Manage shifts Grid based on the Client Billable Item and Billable Date.
    //Used specifically in the Billing Component.
    //The parameter "dateOrAmountSignal" will signify which tab was clicked and how should the Grid be ordered.
    orderManageShiftsGrid = (
        dataSource: eventUkUserGridRowItem[],
        dateOrAmountSignal: boolean
    ): eventUkUserGridRowItem[] => {
        var sortedArray: eventUkUserGridRowItem[] = [];
        if (!dateOrAmountSignal) {
            sortedArray = [...dataSource].sort((a, b) => {
                return -(
                    this.convertCurrencyAsStringToFloat(a.client_Billable_Amount_Local_Ccy) -
                    this.convertCurrencyAsStringToFloat(b.client_Billable_Amount_Local_Ccy)
                );
            });
        }
        return sortedArray;
    };

    //A helper function that will insert 3 nodes namely "dateFrom","dateTo" and "filterText" into the EventUk user grid.
    modifyManageShiftsGridRow = (
        dataSource: eventUkUserGridRowItem[],
        filterText: string,
        dateFrom: string,
        dateTo: string
    ): eventUkUserGridRowItem[] => {
        for (var item of dataSource) {
            (item.statusText = filterText), (item.filterStartDate = dateFrom), (item.filterEndDate = dateTo);
        }
        return dataSource;
    };

    // Convert the server response into the component's form state
    // convertMangeShiftToEventUKGrid(serverResponse: any): eventUkUserGridRowItem {
        convertMangeShiftToEventUKGrid(serverResponse: any): any {
        // var formData: eventUkUserGridRowItem = {
            var formData: any = {
            ...serverResponse,
            billableItemId: serverResponse.id,
            date: serverResponse.dateFrom ? moment(serverResponse.dateFrom).format("DD/MM/YYYY"):serverResponse.dateFrom,
            dateTo: serverResponse.dateTo ? moment(serverResponse.dateTo).format("DD/MM/YYYY"):serverResponse.dateTo,
            client: serverResponse.clientBusinessEntity?.shortName,
            provider: serverResponse.providerBusinessEntity?.shortName,
            venue: '',
            //venueName: serverResponse.venueName,
            venueDetails: {
                houseNumber: serverResponse.venue?.houseNumber,
                venueName: serverResponse.venue?.venueName,
                onboarded: serverResponse.venue?.onboarded
            },
            service: serverResponse.serviceTypeLookUp?.value,
            //role: serverResponse.role,
            //name: serverResponse.name,
            //identification_Number: serverResponse.identification_Number,
            //cover: serverResponse.cover,
            //shift: serverResponse.shift,
            //billable_Hours_Units: serverResponse.billable_Hours_Units,
            //billable_Minutes: serverResponse.billable_Minutes,
            //rate: serverResponse.rate,
            //rate_Decimal: serverResponse.rate_Decimal,
            //cost: serverResponse.cost,
            status: serverResponse.billableStatusLookUp?.value,
            comments: serverResponse.reason,
            //billable_Start_Finish: serverResponse.billable_Start_Finish,
            provider_Tax_Applicable: serverResponse.providerTaxApplicable,
            client_Tax_Applicable: serverResponse.clientTaxApplicable,
            fee_Tax_Applicable: serverResponse.feeTaxApplicable,
            pay_Date: serverResponse.payDate ? moment(serverResponse.payDate).format("DD/MM/YYYY"):serverResponse.payDate,
            bill_Date: serverResponse.billDate ? moment(serverResponse.billDate).format("DD/MM/YYYY"):serverResponse.billDate,
            provider_Pay_Currency_Local_Ccy: serverResponse.providerPayAmountLocalCurrency,
            provider_Pay_Amount_Local_Ccy: "£" + serverResponse.providerPayAmountLocal,
            provider_Pay_Tax_Local_Ccy: "£" + serverResponse.providerPayAmountTaxLocal,
            provider_Pay_Total_Local_Ccy: "£" + serverResponse.providerPayAmountTotalLocal,
            provider_Pay_Original_Amount_Local_Ccy: "£" + serverResponse.providerOriginalPayAmount,
            fee_Currency_Local_Ccy: serverResponse.grossProfitAmountLocalCurrency,
            fee_Amount_Local_Ccy: "£" + serverResponse.fee_Amount_Local_Ccy,
            fee_Tax_Local_Ccy: "£" + serverResponse.fee_Tax_Local_Ccy,
            fee_Total_Local_Ccy: "£" + serverResponse.fee_Total_Local_Ccy,
            fee_Percentage_Local_Ccy: serverResponse.grossProfitPercentageLocal,
            client_Billable_Currency_Local_Ccy: serverResponse.clientBillableAmountLocalCurrency,
            client_Billable_Amount_Local_Ccy: "£" + serverResponse.client_Billable_Amount_Local_Ccy,
            client_Billable_Tax_Local_Ccy: "£" +serverResponse.client_Billable_Tax_Local_Ccy,
            client_Billable_Total_Local_Ccy: "£" +serverResponse.client_Billable_Total_Local_Ccy,
            client_Billable_Original_Amount_Local_Ccy: "£" + serverResponse.client_Billable_Amount_Local_Ccy,
            accept_Date: serverResponse.acceptDate? moment(serverResponse.acceptDate).format("DD/MM/YYYY"):serverResponse.acceptDate,
            approval_Date: serverResponse.approvalDate ? moment(serverResponse.approvalDate).format("DD/MM/YYYY"):serverResponse.approvalDate,
            non_Standard: serverResponse.nonStandard,
            //status_Color: serverResponse.status_Color,
            //status_BackGroundColor: serverResponse.status_BackGroundColor,
            //shift_Color: serverResponse.shift_Color,
            //shift_Font: serverResponse.shift_Font,
            //tenseFlag: serverResponse.tenseFlag,
            //billableStatusLookupId: serverResponse.billableStatusLookupId,
            //absent: serverResponse.absent,
            //unscheduled: serverResponse.unscheduled,
            //shift_Start: serverResponse.shift_Start,
            //shift_End: serverResponse.shift_End,
            //billable_Start: serverResponse.billable_Start,
            //billable_Finish: serverResponse.billable_Finish,
            typeId: '',
            itemTypeValue:serverResponse.billableItemType?.value,
            identificationTypeId: '',
            equipmentQuantity: '',
            serviceTypeId: serverResponse.serviceTypeLookUpId,
            serviceSubTypeId: serverResponse.serviceSubTypeLookUpId,
            //serviceSubTypeValue: serverResponse.serviceSubTypeValue,
            clientId: serverResponse.clientBusinessEntityId,
            providerId: serverResponse.providerBusinessEntity?.id,
            // venueId: serverResponse.venueId,
            // isAmend: serverResponse.isAmend,
            //validationErrors: serverResponse.validationErrors,
            // statusText: '' ,//This node is not a part of the incoming object.
            // filterStartDate: '', //This node is not a part of the incoming object
            // filterEndDate: '', //This node is not a part of the incoming object,
            // paymentRequestID: serverResponse.paymentRequestID,
            // statusId: '',
            // itemTypeId: serverResponse.itemTypeId,
            // approvalDate: serverResponse.approvalDate,
            // statusValue: '',
            // acceptDate: serverResponse.acceptDate,
            // paymentId: serverResponse.paymentId,
            // isAccrual: serverResponse.isAccrual,
            // invoiceId: serverResponse.invoiceId,
            // queries: serverResponse.queries,
            // editAllowed: serverResponse.editAllowed,
            // billDate: serverResponse.billDate,
            // childRecordId: serverResponse.childRecordId,
            subTypeId: serverResponse.serviceSubTypeId,
            // providerFasterPayFee: serverResponse.providerFasterPayFee,
            // providerPayAmountLocal: serverResponse.providerPayAmountLocal,
            //addJobRequestID: serverResponse.addJobRequestID  ,
            //shiftCompleteAcknowledgmentDateTime:serverResponse.shiftCompleteAcknowledgmentDateTime,      
        };
        return formData;
    }

    billableRateDisplayContent = (cellData : any) : string [] => {
        var billableColumnAttributes: string[] = [];
        var billableRate: string = "";
        billableRate = cellData && cellData.rate_Decimal ? cellData.rate_Decimal : "";
        var rateDiffIndicator: string = 
             cellData && cellData.rateDiffIndicator  ? cellData.rateDiffIndicator  : "";
        var isBlue: boolean = false;
        var isRed: boolean = false;
        var classname: string = "";
        var isEventOrClient =
            UserService.isUserInGroup(RoleGroupNames.EventUKRelationshipManager) ||
            UserService.isUserInGroup(RoleGroupNames.EventUKSeniorManager) ||
            UserService.isUserInGroup(RoleGroupNames.VenueManager);

        if (
          
            (rateDiffIndicator && rateDiffIndicator.toLowerCase() == "less rate")
        ) {
            //Guard clause
            if (isEventOrClient) {
                isBlue = true;
            } else {
                isRed = true;
            }
        } else if (
         
            rateDiffIndicator && rateDiffIndicator.toLowerCase() == "more rate"
        ) {
            if (isEventOrClient) {
                isRed = true;
            } else {
                isBlue = true;
            }
        }

        if (isBlue) {
            classname = "check-blue";
        }
        if (isRed) {
            classname = "check-red";
        }
        billableColumnAttributes.push(classname);
        billableColumnAttributes.push(billableRate);
        return billableColumnAttributes;
    }

    convertH2M = (timeInHour:any): any =>{
        var timeParts = timeInHour !== undefined ? timeInHour.split(":") : "";
        return Number(timeParts[0]) * 60 + Number(timeParts[1]);
    }

    convertToHoursMinutesFormat = (timeInHour: any): any => {
        let timeParts = timeInHour !== undefined ? timeInHour.split(":") : "";
        let hoursString = '';
        if (timeParts && timeParts.length > 0) {
            if(Number(timeParts[0])){
                let hour = timeParts[0] + ' hr';
                hoursString += hour;
            }
            
           
            if (timeParts?.length > 1) {
                if(Number(timeParts[1])){
                    let min = ' ' + timeParts[1] + ' min';
                    hoursString += min;
                }
                
            }
        }
        return hoursString;
    }

    convertMinutesStringToHours =(minutesInString:string):any=>{
        let timeString = '';
        if(minutesInString){
            let hours = (Number(minutesInString) / 60).toString(); 
            if(hours && Number(hours)){
                let splits = hours.split(".");
                if(splits && splits.length > 0){
                    if(Number(splits[0])){
                        timeString += splits[0] + ' hr';
                    }
                    
                }
                
            }
             let minutes = Number(minutesInString) % 60;
             if(minutes){
                timeString += ' ' + minutes + ' min'
             }
        }
        return timeString;
    }
    
    /// <summary>
        /// Returns adjusted finish time.
        /// </summary>
        /// <param name="start">start time.</param>
        /// <param name="finish">finish time.</param>
        /// <returns></returns>
        AdjustFinishTime = (start : string, finish: string): string => {
            // if start is say 20:00 and finish times is 03:00 we add 1 day to finish time
            let startMin = start !== undefined ? this.convertH2M(start) : "";
            let finishMin = finish !== undefined ? this.convertH2M(finish) : "";
            let startDT = moment().format('YYYY-MM-DD');
            let finishDT = moment().format('YYYY-MM-DD');
            let startDateTime = moment(startDT).add(startMin, 'minutes').format('YYYY-MM-DD hh:mm A');
            let finishDateTime = moment(finishDT).add(finishMin, 'minutes').format('YYYY-MM-DD hh:mm A');           
            let beginningTime = moment(start, 'h:mm');
            let endTime = moment(finish, 'h:mm');
            let defaultTime = moment("00:00", 'h:mm');
            let otherTime = moment("05:01", 'h:mm');

            if (start)
            {                 
                if (new Date(startDateTime) >= new Date(finishDateTime))
                {                    
                    let dte = moment(finishDateTime).add(1, 'd').format('YYYY/MM/DD hh:mm A');
                    // return moment(dte).add(finishMin, 'minutes').format('YYYY-MM-DD hh:mm A');
                    return dte;
                }
                else
                {                   
                    // if both start and finish times after midnight and before 5am  we add 1 day to finish time
                    if (defaultTime.isBefore(beginningTime) && beginningTime.isBefore(otherTime)
                        && defaultTime.isBefore(endTime) && endTime.isBefore(otherTime))
                    {
                        let dte = moment(finishDateTime).add(1, 'd').format('YYYY/MM/DD hh:mm A');
                        // return moment(dte).add(finishMin, 'minutes').format('YYYY-MM-DD hh:mm A');
                        return dte;
                    }
                    else
                    {
                        return moment(finishDT).add(finishMin, 'minutes').format('YYYY-MM-DD hh:mm A');
                    }
                }
            }
            else
            {
                if (defaultTime.isBefore(endTime) && endTime.isBefore(otherTime))
                {
                    let dte = moment(finishDateTime).add(1, 'd').format('YYYY/MM/DD hh:mm A');
                    return moment(dte).add(finishMin, 'minutes').format('YYYY-MM-DD hh:mm A');
                }
                else
                {
                    return moment(finishDT).add(finishMin, 'minutes').format('YYYY-MM-DD hh:mm A');
                }
            }
        }

        /// <summary>
        /// Returns adjusted start time.
        /// </summary>
        /// <param name="start">start time.</param>
        /// <param name="finish">finish time.</param>
        /// <returns></returns>
        AdjustStartTime = (start : string, finish: string): string => {
            let startMin = start !== undefined ? this.convertH2M(start) : "";
            let startDT = moment().format('YYYY-MM-DD');
            let startDateTime = moment(startDT).add(startMin, 'minutes').format('YYYY-MM-DD hh:mm A');        
            let beginningTime = moment(start, 'h:mm');
            let endTime = moment(finish, 'h:mm');
            let defaultTime = moment("00:00", 'h:mm');
            let otherTime = moment("05:01", 'h:mm');

            if (defaultTime.isBefore(beginningTime) && beginningTime.isBefore(otherTime) && defaultTime.isBefore(endTime) && endTime.isBefore(otherTime))
            {
                let dte = moment(startDateTime).add(1, 'd').format('YYYY/MM/DD hh:mm A');
                // return moment(dte).add(startMin, 'minutes').format('YYYY-MM-DD hh:mm A');
                return dte;
            }
            else
            {
                return moment(startDT).add(startMin, 'minutes').format('YYYY-MM-DD hh:mm A');
            }
        }
}
